<template>
    <w-dialog :fullscreen="$vuetify.breakpoint.xsOnly" max-width="60vw" :title="$t('customers.add_customer')" :value="dialog" @input="onInput($event)">
        <CustomerCreationWizard ref="wizard" :accounting-firm-id="accountingFirmId" @customer-created="onCustomerCreated($event)" />
    </w-dialog>
</template>

<script>
export default {
    name: 'CustomerCreationDialog',
    components: {
        CustomerCreationWizard: () => ({
            component: import('@/components/CustomersManager/CustomerCreationWizard')
        })
    },
    provide: function () {
        return {
            service: this.service
        }
    },
    props: {
        accountingFirmId: {
            default: null,
            required: false,
            type: Number
        },
        service: {
            required: true,
            type: Object
        },
        value: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        dialog: {
            get: function () {
                return this.value
            },
            set: function (value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        onCustomerCreated: function (vendor) {
            this.onInput(false)
            this.$emit('customer-created', vendor)
        },
        onInput: function (dialog) {
            if (!dialog) {
                this.$refs.wizard.reset()
                this.dialog = false
            }
        }
    }
}
</script>